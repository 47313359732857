import React, { FC, useCallback } from 'react';
import styles from './Header.module.scss';
import { useDispatch } from 'react-redux';
import { UserActions } from '../../store/user';
import Button from '../Button/Button';
import { NavLink } from 'react-router-dom';

interface Props {}

const Header: FC<Props> = () => {
  const dispatch = useDispatch();
  const onLogout = useCallback(() => {
    dispatch(UserActions.logout());
  }, [dispatch]);

  return (
    <div className={styles.header}>
      <div className={styles.navWrapper}>
        <div className={styles.logo} />
        <nav className={styles.nav}>
          <NavLink exact to="/" className={styles.navLink} activeClassName={styles.active}>
            이엠아이/보험
          </NavLink>
          <NavLink to="/register" className={styles.navLink} activeClassName={styles.active}>
            등록원부
          </NavLink>
          <NavLink to="/vin" className={styles.navLink} activeClassName={styles.active}>
            차대번호 조회
          </NavLink>
        </nav>
      </div>
      <Button className={styles.logout} onClick={onLogout}>
        로그아웃
      </Button>
    </div>
  );
};

export default Header;
