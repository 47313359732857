import React from 'react';
import VehicleRegisterForm from '../components/VehicleRegisterForm/VehicleRegisterForm';
import VehicleRegister from '../components/VehicleRegister/VehicleRegister';
import Layout from '../components/Layout/Layout';

const VehicleRegisterPage: React.FC<{}> = () => {
  return (
    <Layout>
      <VehicleRegisterForm />
      <VehicleRegister />
    </Layout>
  );
};

export default VehicleRegisterPage;
