import React, { FC, FormEvent } from 'react';
import styles from './TextInput.module.scss';
import classNames from 'classnames';
import { useFocus } from '../../hooks/useFocus';
import { CSSTransition } from 'react-transition-group';

interface Props {
  className?: string;
  icon?: React.ReactNode;
  type?: string;
  name?: string;
  value: string;
  placeholder?: string;
  onChange: (e: FormEvent<HTMLInputElement>) => void;
  inputClassName?: string;
}

const TextInput: FC<Props> = ({ className, placeholder = '', type = 'text', name, value, icon, onChange, inputClassName }) => {
  const [isFocus, onFocus, onBlur] = useFocus();
  const isDisplayPlaceholder = value === '' && placeholder !== '';

  return (
    <div className={classNames(styles.textInput, isFocus && styles.isFocus, icon && styles.hasIcon, className)}>
      <div className={classNames(styles.input, inputClassName)}>
        {icon && <span className={styles.icon}>{icon}</span>}
        {/*<CSSTransition in={isDisplayPlaceholder} timeout={300} classNames="" mountOnEnter unmountOnExit>*/}
        {/*  <span className={styles.placeholder}>{placeholder}</span>*/}
        {/*</CSSTransition>*/}
        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default TextInput;
