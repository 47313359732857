import React from 'react';
import Layout from '../../components/Layout/Layout';
import CarSpec from '../../components/CarSpec/CarSpec';
import CarSpecForm from './CarSpecForm/CarSpecForm';

const CarSpecPage: React.FC<{}> = () => {
  return (
    <Layout>
      <CarSpecForm />
      <CarSpec />
    </Layout>
  );
};

export default CarSpecPage;
